import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SessionOption } from 'src/app/shared/models/SessionOption';
import { FormErrorHandler } from 'src/app/shared/utils/FormErrorHandler';
import { UtilsService } from '../../shared/services/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DisclaimerDialogData } from 'src/app/shared/models/DisclaimerDialogData';
import { DisclaimerDialogComponent } from 'src/app/shared/components/disclaimer-dialog/disclaimer-dialog.component';
import { filter, firstValueFrom, lastValueFrom, map, take } from 'rxjs';
import { WebSocketMessage } from 'src/app/shared/models/WebSocketMessage';
import { CreateLiveDto } from 'src/app/shared/models/CreateLiveDto';
import { InvitedPeopleDto } from 'src/app/shared/models/InvitePeopleDto';
import { MatDialog } from '@angular/material/dialog';
import { OrganisationService } from 'src/app/shared/services/organisation.service';
import { UserService } from 'src/app/shared/services/user.service';
import { LiveService } from 'src/app/shared/services/live.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { WebSocketClientService } from 'src/app/shared/services/web-socket-client.service';
import { MedinboxService } from 'src/app/shared/services/medinbox.service';
import { MessageService } from 'src/app/shared/services/message.service';
import { LiveType } from 'src/app/shared/models/enums/LiveType';
import { RoleEnum } from 'src/app/shared/models/enums/LiveRole';
import { WebSocketActionEnum } from 'src/app/shared/models/enums/WebsocketActions';
import { ICommunicationService } from 'src/app/shared/services/communication.service';
import { Live } from 'src/app/shared/models/LiveDto';

@Component({
  selector: 'app-start-live',
  templateUrl: './start-live.component.html',
  styleUrls: ['./start-live.component.scss'],
  standalone: false,
})
export class StartLiveComponent implements OnInit, AfterViewInit {
  public sessionOption: SessionOption = {
    chatRoomEnabled: false,
    recordSession: false,
    secureWithPIN: false,
    permanentLive: false,
  };
  form!: FormGroup;
  moderatorInvites: string[] = [];
  contactInvites: string[] = [];
  private generatedSessionCode: string = '';
  private sessionDefaultTitle: string = 'New Medinbox Link Case';
  errors: any = {};
  isGenerate: boolean = true;
  isDetails: boolean = false;
  origin = window.location.origin;
  sessionCode = '';

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private utilsService: UtilsService,
    private errorHandler: FormErrorHandler,
    private fb: UntypedFormBuilder,
    private organisationService: OrganisationService,
    private liveService: LiveService,
    @Inject('ICommunicationService') private communicationService: ICommunicationService,

    private loader: LoaderService,
    private translate: TranslateService,
    private webSocketClient: WebSocketClientService,
    private medinboxService: MedinboxService,
    private errorService: MessageService,
    public userService: UserService,
    public utils: UtilsService,
    public route: ActivatedRoute,
  ) {}

  async ngOnInit(): Promise<void> {
    this.initializeForm();
    this.subscribeToParamMap();
    this.subscribeToFormChanges();
    await this.refreshOrganisationData();
  }

  private initializeForm(): void {
    this.form = this.fb.group({
      title: [this.sessionDefaultTitle, [Validators.required, Validators.minLength(4), this.errorHandler.noWhitespaceValidator]],
      cohostEmail: ['', [this.errorHandler.multipleEmailValidator]],
      description: [''],
      reference: ['', [Validators.minLength(4), Validators.required]],
      contact: ['', [this.errorHandler.multipleEmailValidator]],
      studyType: [''],
      physician: [''],
      equipmentId: [null],
      organizerEmail: [null],
      liveId: [null],
    });
  }

  private subscribeToParamMap(): void {
    this.route.paramMap.subscribe((params) => {
      const reference = params.get('reference') ?? this.utilsService.generateConferenceCode();
      if (params.get('reference')) {
        this.populateFormWithLiveData(reference);
      }
      this.form.patchValue({ reference: reference });
    });
  }

  private populateFormWithLiveData(reference: string): void {
    this.liveService.getLive(reference).subscribe((live: Live) => {
      this.form.patchValue({
        title: live?.title ?? undefined,
        physician: live.physician,
        studyType: live.studyType ?? undefined,
        description: live.description,
        equipmentId: live.equipmentId,
        organizerEmail: live.organizerEmail,
        liveId: live.liveId,
      });
      this.moderatorInvites = live.usersInformations.filter((userInfo) => userInfo.role == RoleEnum.Moderator).map((userInfo) => userInfo.email);
      this.contactInvites = live.usersInformations.filter((userInfo) => userInfo.role == RoleEnum.Guest).map((userInfo) => userInfo.email);
    });
  }

  private subscribeToFormChanges(): void {
    this.form.valueChanges.subscribe((val) => {
      this.errors = {};
      this.errorHandler.handleErrors(this.form, this.errors);
    });
  }

  private async refreshOrganisationData(): Promise<void> {
    if (!this.organisationService.currentOrganisations$.getValue()) {
      this.organisationService.refreshUserOrganisation();
    }

    this.organisationService.currentOrganisations$
      .pipe(
        filter((o) => o != null),
        take(1),
        map(async () => {
          await this.userService.refreshCurrentUserData();
          await this.initializeWebSocketClient();
        }),
      )
      .subscribe();
  }

  private async initializeWebSocketClient(): Promise<void> {
    if (
      !this.webSocketClient.socketMedinbox &&
      (await this.userService.isMedinboxEquipment()) &&
      (this.medinboxService.medinboxId$.getValue() || localStorage.getItem('equipmentId'))
    ) {
      let currentEquipmentId = this.medinboxService.medinboxId$.getValue();
      if (!currentEquipmentId) {
        currentEquipmentId = localStorage.getItem('equipmentId');
        this.medinboxService.medinboxId$.next(currentEquipmentId);
      }
      if (currentEquipmentId) {
        this.webSocketClient.initMedinboxChannel(currentEquipmentId);
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.loader.status()) {
      this.loader.hide();
    }
  }

  addModerator(val: string): void {
    if (!this.form.controls.cohostEmail.invalid && val) {
      const inputEmails = this.form.get('cohostEmail')?.value ?? '';
      const emails = inputEmails.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      this.addModerators(emails);
      this.form.get('cohostEmail')?.setValue('');
    }
  }

  addContact(val: string): void {
    if (!this.form.controls.contact.invalid && val) {
      const inputEmails = this.form.get('contact')?.value ?? '';
      const emails = inputEmails.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      this.addContacts(emails);
      this.form.get('contact')?.setValue('');
    }
  }

  remove(val: string, invites: string[]): void {
    const index = invites.indexOf(val);

    if (index >= 0) {
      invites.splice(index, 1);
    }
  }

  handleFocusOutConfTitle() {
    if (!this.form.get('title')?.value) {
      this.form.get('title')?.setValue(this.sessionDefaultTitle);
    }
  }

  handleFocusTitle() {
    if (this.form.get('title')?.value == this.sessionDefaultTitle) {
      this.form.get('title')?.setValue('');
    }
  }

  async createALive() {
    if (await this.disclaimer()) {
      const confTitle = this.form.get('title')?.value || this.generatedSessionCode;
      let invitedPeoples: InvitedPeopleDto[] = [];
      const inFieldModeratorsEmail = this.form.get('cohostEmail')?.value;
      const moderatorsEmails = inFieldModeratorsEmail.split(/[\s,;]+/).filter((email: string) => email.length > 0);
      this.addModerators(moderatorsEmails);

      const inFieldContactsEmail = this.form.get('contact')?.value;
      const contactsEmails = inFieldContactsEmail.split(/[\s,;]+/).filter((email: string) => email.length > 0);

      this.addContacts(contactsEmails);

      if (this.moderatorInvites.length > 0) {
        this.moderatorInvites.forEach((element) => {
          invitedPeoples.push({
            email: element,
            role: RoleEnum.Moderator,
          } as InvitedPeopleDto);
        });
      }

      if (this.contactInvites.length > 0) {
        this.contactInvites.forEach((element) => {
          invitedPeoples.push({
            email: element,
          });
        });
      }

      const createLive = {
        organizationId: this.organisationService.currentOrganisations$.getValue()?.organizationId as number,
        reference: this.form.get('reference')?.value.trim(),
        title: confTitle,
        studyType: this.form.get('studyType')?.value,
        liveId: this.form.get('liveId')?.value,
        physician: this.form.get('physician')?.value,
        isReservedAlias: false,
        description: this.form.get('description')?.value,
        equipmentId: this.form.get('equipmentId')?.value ?? this.medinboxService.medinboxId$.getValue() ?? null,
        liveType: LiveType.Public,
        invitedPeoples: invitedPeoples,
        organizerEmail:
          this.form.get('organizerEmail')?.value ??
          (this.medinboxService.medinboxId$.getValue() ? this.medinboxService.medinboxId$.getValue() + '@medinbox.com' : null),
      } as CreateLiveDto;
      this.loader.display();
      try {
        await this.startLive(createLive, invitedPeoples);
      } catch (err) {
        this.loader.hide();
      }
    }
  }

  private addContacts(contactsEmails: any) {
    contactsEmails.forEach((newEmail: string) => {
      if (this.contactInvites.findIndex((s) => s.toLowerCase() == newEmail.toLowerCase() && this.form.get('owner')?.value != newEmail) == -1) {
        this.contactInvites.push(newEmail);
        const existingIndexInModerators = this.moderatorInvites.indexOf(newEmail);
        if (existingIndexInModerators != -1) {
          this.moderatorInvites.splice(existingIndexInModerators, 1);
        }
      }
    });
  }

  private addModerators(moderatorsEmails: any) {
    moderatorsEmails.forEach((newEmail: string) => {
      if (this.moderatorInvites.findIndex((s) => s.toLowerCase() == newEmail.toLowerCase() && this.form.get('owner')?.value != newEmail) == -1) {
        this.moderatorInvites.push(newEmail);
        const existingIndexInInvites = this.contactInvites.indexOf(newEmail);
        if (existingIndexInInvites != -1) {
          this.contactInvites.splice(existingIndexInInvites, 1);
        }
      }
    });
  }

  private async startLive(createLive: CreateLiveDto, invitedPeoples: InvitedPeopleDto[]) {
    const live = await lastValueFrom(this.liveService.createLive(createLive));
    const liveStarted = await lastValueFrom(this.communicationService.startConference(live.reference));
    this.liveService.currentLive$.next(liveStarted);
    localStorage.setItem('currentLive', JSON.stringify(liveStarted));
    await this.webSocketClient.initWebSocketService(liveStarted.reference);
    await this.communicationService.startSession();

    const started = await this.communicationService.joinConference(liveStarted.vonageSessionId as string);
    if (started != 'OK') {
      this.loader.hide();
      if (started == 'Permission denied in browser') {
        this.errorService.showError(
          `<span>Your browser can't access the required devices</span>
              <br/>
              <br/>
              <span>If you need help to allow device access, you can have a look 
              <a href="/assets/HowTo/how-to-allow-device-access.html" target="_blank">here</a>.</span>
              <br/>
              <br/>
              <span>If the problem persists please contact Medinbox support <br/> at <a href=\"mailto:support@medinbox.com\">support@medinbox.com</a></span>`,
        );
      } else {
        this.errorService.showError(
          `<span>${started}</span><br/><span>If the problem persists please contact Medinbox support <br/> at <a href=\"mailto:support@medinbox.com\">support@medinbox.com</a></span>`,
        );
      }
      this.communicationService.leaveSession(true);
      await lastValueFrom(this.liveService.endLive(live.reference));
      await this.liveService.leaveLive();
      this.router.navigate([]);
    } else if (await this.userService.isMedinboxEquipment()) {
      lastValueFrom(
        this.liveService.sendEmailInvitation({
          liveReference: this.liveService.currentLive$.getValue()?.reference ?? 'no-live-found',
          invitedPeoples: invitedPeoples,
        }),
      );
      await this.webSocketClient.sendMessage({
        audience: 'LIVE',
        sourceId: this.userService.getCurrentUserId(),
        message: { action: WebSocketActionEnum.STARTED, params: { LiveReference: live.reference, LiveId: live.liveId } },
        destinationId: this.liveService.currentLive$.getValue()?.reference,
      } as WebSocketMessage);
      var liveForEquipment = {
        LiveReference: live.reference,
        LiveId: live.liveId,
        LiveTitle: live.title,
        LiveDescription: live.description,
        LiveStudyType: live.studyType,
        LivePhysician: live.physician,
      };
      await this.webSocketClient.sendMedinboxMessage({
        message: {
          action: WebSocketActionEnum.STARTED,
          params: liveForEquipment,
        },
        audience: 'EQUIPMENT',
        sourceId: this.userService.getCurrentUserId(),
        destinationId: this.medinboxService.medinboxId$.getValue(),
        liveReference: this.liveService.currentLive$.getValue()?.reference ?? null,
      } as WebSocketMessage);
      this.router.navigate(['live'], {
        queryParams: {
          live: this.liveService.currentLive$.getValue()?.reference,
          name: this.liveService.currentLive$.getValue()?.title,
          conferenceId: this.communicationService.currentSession$.getValue().sessionId,
        },
      });
    }
  }

  private async disclaimer(): Promise<boolean> {
    const dialogRef = this.dialog.open(DisclaimerDialogComponent, {
      minWidth: '300px',
      maxWidth: '500px',
      data: {
        yesNoDialog: true,
        title: this.translate.instant('LIVE.JOIN_LIVE.EQUIPMENT_DISCLAIMER_TITLE'),
        text: this.translate.instant('LIVE.JOIN_LIVE.EQUIPMENT_DISCLAIMER_TEXT'),
        yesNoButton: false,
      } as DisclaimerDialogData,
      panelClass: ['disclaimer-modal', 'disclaimer-modal-full-height'],
    });

    return (await firstValueFrom(dialogRef.afterClosed())) ?? false;
  }
}
